@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap);

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Lato", sans-serif;
}

p,
a,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Lato", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #000000;
}

img {
  width: 100%;
}

