.mortGageContainer {
  background-image: url("./assets/images/container-background-image.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}

.mortgageBlock {
  height: 100%;
  background-color: rgba(26, 188, 156, 0.6);
  text-align: center;
  backdrop-filter: blur(5px);
}

.mortgageBlock img {
  max-width: 300px;
  margin: 20px 0;
}

.mortgageBlock h2 {
  font-size: 30px;
}

.mortgageBlock p {
  font-size: 16px;
  margin-top: 5px;
}

.surveyContainer {
  margin-top: 30px;
}

.surveyContainer h2 {
  font-size: 28px;
  margin-bottom: 20px;
}

.questionContainer h3 {
  font-size: 25px !important;
  color: #000 !important;
  font-weight: 600 !important;
  margin-bottom: 25px;
}

.mortgageButton {
  border: 1px solid #328e6b !important;
  color: #fff !important;
  font-weight: 500 !important;
  background: #328e6b !important;
  font-size: 1rem !important;
  text-transform: capitalize !important;
  font-size: 19px !important;
  padding: 5px 20px !important;
}

.questionContainer {
  display: flex !important;
  width: 100%;
  max-width: 70%;
  margin: 0 auto;
}

.questionContainer button {
  margin-bottom: 20px;
  /* border: 1px solid #13518e !important; */
  border-radius: 8px !important;
  font-weight: 600;
  color: #fff;
  background: #328e6b;
  font-size: 18px;
  padding: 5px 0px;
}

/* .questionContainer button:hover {
  color: #3fb488;
} */

.formField {
  background-color: rgba(26, 188, 156, 0.6);
  color: #000;
}

.formField .Mui-focused {
  color: #000 !important;
}

.formField .Mui-focused:after {
  border-bottom: 2px solid #328e6b !important;
}

.contactContainer button {
  width: 100%;
  max-width: 200px;
  margin-top: 30px;
}

.formField label {
  color: #121212;
  font-weight: 500;
}

button svg {
  color: #fff;
}

.disableBtn, form .Mui-disabled {
  background: grey !important;
}

.disableBtn:hover, form .Mui-disabled:hover {
  color: #fff !important;
}

.inputQuestionContainer {
  flex-direction: column !important;
}

.inputQuestionContainer input {
  border: none;
    height: 40px;
    border-radius: 5px;
    width: 100%;
    padding: 0 15px;
    margin-bottom: 20px;
}

.btnContainer {
  display: flex;
  justify-content: space-between;
}

.btnContainer button {
  width: 150px;
  border: none;
  padding: 9px 5px;
  border: 2px solid transparent;
  cursor: pointer;

}

.btnContainer button:not(.disableBtn):hover {
  background: none;
  border-color: #13518e !important;
}

@media only screen and (max-width: 550px) { 
  .mortgageBlock h2 {
    font-size: 25px;
  }
  .mortgageBlock {
    padding-top: 20px;
  }
  .mortgageBlock img {
    max-width: 300px;
  }
  .btnContainer {
    column-gap: 20px;
  }
  .questionContainer button {
    font-size: 16px !important;
  }
}


